export const colors = {
  primary: "#222831",
  secondary: "#393E46",
  tertiary: "#EEEEEE",
  accent: "#00ADB5",
  darkGray: "#111827",
  lightGray: "#6B7280",
  white: "#fff",
  black: "#000",
  success: "#22C55E",
  fail: "#EF4444",
};
